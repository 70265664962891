.field-label{
    display: block;
    padding-bottom: 4px;
    font-weight: 500;

    &.required::after {
        display: inline-block;
        margin-left: 2px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.table-actions {
    span {
        display: flex;
    }
}

.form_body {

    .ant-form-item-has-error {
        input{
            border-color: #ff4d4f !important;
        }
    }
}

.form-radio-group {
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-label > label {
        color: @secondary-color;
        font-size: 16px !important;
        font-weight: 600;
        margin-bottom: 10px;
        &::after{
            content: "";
        }
    }

    .ant-radio-wrapper {

        span {
            color: @secondary-color;
            font-size: 14px;
            
        }

        span.ant-radio.ant-radio-checked + span {
            display: flex;
            align-items: baseline;
            color: @secondary-color;

            div.ant-form-item-explain.ant-form-item-explain-connected {
                margin-left: 35px;
            }
        }

        span.ant-input-prefix {
            color: @primary-color;
        }
    }

    &.ant-form-item{
        margin-bottom: 8px !important;
    }
}


.ant-btn.ant-btn-default.tag-buttons,
.ant-btn.tag-buttons  {
    height: auto;
    padding: 15px 8px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 8px;
    border-color: @dark-gray;
    color: @secondary-color;

    &.ant-btn-primary {
        border-color: @primary-color;
        color: @white;
    }
}

.sd-simple-form {
    .ant-typography {
        color: @secondary-color;
    }

    .ant-form-item { 
        .ant-input-prefix {
            margin-right: 0px;
        }
    }
}

.simple-form-container{
    border-radius: 10px;
    padding: 30px;
    // max-width: 1100px;
    .avatar-uploader {
        .ant-upload {
            width: 100%;
            height: 142px;
            overflow: hidden;
        }

        .ant-upload-list-picture-card-container{
            position: absolute;
            top: 0;
            left: 0;

            margin: 0;
            width: 100%;
            height: 130px;

            .ant-upload-list-item-thumbnail img {
                object-fit: cover;
            }
            .ant-upload-list-item-actions {
                display: flex;
                align-items: center;

                >a {
                    margin-top: -4px;
                }
            }
        }
    }


    @media @max768 {
        padding: 20px;

        .ant-col {
            min-width: 142px;
        }
    }
}

