
.add-on-label {
  font: normal normal medium 16px/24px Avenir;
  font-weight: bold;
  color: #014050;
  margin-right: 20px;

  @media (max-width: 426px) {
    display: none;
  }
}

.add-on-select {
  width: 50%;

  @media (max-width: 426px) {
    width: 100%;
  }
}

.item-image-border-radius {
  border-radius: 6px;
  object-fit: cover !important;

  &.image_placeholder {
    object-fit: contain !important;
  }

  @media (max-width: 768px) {
    max-height: 300px;
  }
}

.item-highlight-detail-container {
  padding: 0px 0 0px 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px 0;
  }

  @media (max-width: 426px) {
    padding: 10px 5px;
  }


  .item-highlight-title {
    font: normal normal 600 24px/32px Avenir;
  }

  .item-highlight-category {
    font: normal normal normal 14px/24px Avenir;
  }

  .item-highlight-description {
    color: #A8A8A8;
    font: normal normal normal 14px/20px Avenir;
  }

  .item-highlight-actions-container {
    margin-top: auto;
    color: #A8A8A8;
  }
  
  .item-highlight-actions {
    cursor: pointer;
  }

  .item-highlight-actions:hover {
    color: @primary-color;
    transition: .3s ease-in-out;
  }

  .item-highlight-actions-detail-btn {
    // width: 100%;
    // text-align: right;

    // @media (max-width: 768px) {
    //   text-align: left;
    // }

    span {
      font-weight: 600;
      border-bottom: 2px solid #A8A8A8;

      &:hover {
        transition: .3s ease-in-out;
        border-bottom: 2px solid @primary-color;
      }
    }
  }

}

.item-detail-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-right: 1px solid #dfdfdf;

  &:nth-child(3n) {
    border-right: none;
  }
  &:nth-child(-n+3) {
    padding-top: 0 !important;
  }

  @media (max-width: 991px) {
    &:nth-child(even) {
      border-right: none;
    }
    &:nth-child(odd) {
      border-right: 1px solid #dfdfdf !important;
    }
    &:nth-child(-n+2) {
      padding-top: 0 !important;
    }
    &:nth-child(3) {
      padding-top: 30px !important;
    }
  }

  @media (max-width: 768px) {
    &:nth-child(2) {
      padding-top: 30px !important;
    }
  }
  @media (max-width: 767px) {
    &:nth-child(odd) {
      border-right: none !important;
    }
  }


  .item-title {
    font: normal normal 600 16px/24px Avenir;
  }

  .item-category {
    font: normal normal normal 14px/24px Avenir;
  }

  .item-actions-container {
    margin-top: auto;
    padding-top: 10px;
    color: #A8A8A8;
  }

  .item-actions {
    cursor: pointer;
  }

  .item-actions:hover {
    color: #00ad4c;
  }

  .item-actions-detail-btn {
    // width: 100%;
    // text-align: right;

    // @media (max-width: 768px) {
    //   text-align: left;
    // }

    span {
      font-weight: 600;
      border-bottom: 2px solid #A8A8A8;

      &:hover {
        transition: .3s ease-in-out;
        border-bottom: 2px solid @primary-color;
      }
    }
  }

}

// .item-detail-bordered-container {
//   border-left: 1px solid rgba(0, 0, 0, 0.15);
//   border-right: 1px solid rgba(0, 0, 0, 0.15);

//   @media @max768 {
//     border: none !important;
//   }
// }

.tips__suggested-prod {
  @media (min-width: 992px) {
    padding: 0 0 0 10px;
  }
  @media (max-width: 991px) {
    padding-top: 10px;
  }

  .ant-table-empty {
    background-color: @white !important;
  }

  .ant-image-img {
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;

    &.image_placeholder {
      object-fit: contain;
      background-color: #e7e9ef;
    }

    @media (max-width: 767px) {
      border-radius: 10px 10px 0 0;
    }
  }
}
.tip-hidden-xs {
  // display: block;
}
.tip-visible-xs {
  display: none;
}

@media (max-width: 991px) {
  .tip-hidden-xs {
    display: none;
  }
  .tip-visible-xs {
    display: block;
  }
}

.tip-linked-products-list {
  width: 100%;
  padding-bottom: 20px;

  .linked-product-name {
    padding: 40px 20px 10px 30px;

    .product-name-txt {
      font: normal normal 600 16px/24px Avenir;
    }
  }

  .linked-product-category {
    padding: 0px 30px;

    .product-category-txt {
      color: #A8A8A8;
    }
  }

  .linked-product-actions {
    padding: 30px 20px 10px 30px;

    .product-category-remove-btn {
      color: #A8A8A8;
      cursor: pointer;
    }

    .product-category-remove-btn:hover {
      color: @tertiary-color;
    }

    @media @max768 {
      margin: 100px 30px 0px 0px;
    }

    @media (max-width: 426px) {
      margin: 0px;
    }
  }
}

.sd-pagination {

  .ant-table {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    table {

      tr {
        color: @secondary-color;
        font-weight: 600;
      }
    }

    span.c-pointer.link-primary {
      color: @dark-gray;

      &:hover {
        color: @primary-color;
      }
    }

    .table-actions {

      span.c-pointer.link-primary {
        margin-right: 15px;
      }

    }

  }

  .ant-pagination.ant-table-pagination {
    padding: 10px 0;
  }

  .ant-table-thead > tr > th {
    background: none;
    color: @white;
  }

  .ant-table-thead {
    background-color: @secondary-color;

    tr {
      background-color: @secondary-color;
    }
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    // border-top-left-radius: @border-radius-default;
    padding: 25px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    // border-top-right-radius: @border-radius-default;
  }

  .ant-pagination-item,
  .ant-pagination-prev > button,
  .ant-pagination-next > button {
    border: none;
    color: @secondary-color;
    font-weight: 700;
    margin-right: 6px;
    border-radius: 10px;
  }

  .ant-pagination-disabled > button {
    border: none;
    box-shadow: none;
    color: #A8A8A8;
  }


  .ant-pagination-item > a{
    font-family: 'Avenir';
    color: @secondary-color;
  }
    

  .ant-pagination-item-active {
    font-family: 'Avenir';
    background-color: @primary-color;
    border: initial;
  }
  .ant-pagination-item-active a {
    color: white;
  }
  .ant-pagination-item-active a:hover {
    color: white;
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: transparent;
  }

  @media (max-width: 426px) {
    .ant-pagination {
      justify-content: center;
    }
  }
}