@import '../views-dir.less';

.notifications {

  &__header-list {
    width: auto;
    padding: 24px;
    margin: 0 -24px;
    min-height: 60px;
    position: relative;
    color: @secondary-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: .12s ease-in-out;

    &:first-child {
      margin-top: -24px;
    }

    &:hover {
      background-color: @body;
    }

    &.unread {
      font-weight: 600;
      cursor: pointer;
    }

    .title {
      font-weight: 600;
      padding-right: 5px;
      color: @primary-color;
    }

    .time-updated {
      font-size: 12px;
      margin-top: 10px;
      color: @primary-color;

      display: flex;
      align-items: center;
      
      .anticon {
        font-size: 14px;
        padding-right: 6px;
      }
    }

    .isRead {
      position: absolute;
      top: 50%;
      right: 20px;

      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: @primary-color;
    }
  }

  &__all-list {

    .header {
      font-size: 18px;
    }

    .sd-pagination {
      position: relative;
      .ant-pagination {
        position: absolute;
        right: 0;
        padding: 25px 0 !important;
      }
    }

    .text-primary {
      cursor: pointer;
      font-weight: 600;
      transition: .15s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }

    .ant-table-thead {
      display: none;
    }

    .notifications__header-list {
      margin: 0;
      padding: 0;
      border-bottom: 0;

      .time-updated {
        margin-top: 0;
        padding-right: 2rem;
      }
      .isRead {
        right: 0;
      }

      &:hover {
        background: transparent;
      }

      @media (max-width: 768px) {
        display: block;
        .time-updated {
          margin-top: 10px;
          padding-right: 0;
        }
      }
    }
  }
}
@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;