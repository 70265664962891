.header-rightIcon {
  .ant-scroll-number {
    z-index: 1;
  }
}
.icon-container {
    .notification-icon {
        >svg >g >circle {
            display: none;
        }
        >svg >g >g >path {
            stroke: @primary-color !important;
        }
    }
}

#sd-user-name{
  margin: 0px 5px;
}

.sd-nav-bar {
  padding: 8px 24px;
}

.sd-dropdown {

  min-width: 150px;
  left: -10px !important;
  top: 30px;

  &::before {
    content: "\A";
    position: absolute;
    right: 0;
    top: -10px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ffffff transparent transparent;
  }
}

.modal-notifications {
    position: absolute;
    right: 0px;
    top: 70px !important;
  
    .ant-modal-content {
      position: relative;
  
      &:before {
        content: "\A";
        position: absolute;
        right: 0;
        top: -10px;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent #ffffff transparent transparent;
      }

      .ant-modal-close {
        display: none;
      }
  
      .ant-modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        >a {
          font-size: 14px;
          font-weight: 600;
          color: @primary-color;
        }
      }

      .ant-modal-body {
        height: 500px !important;
      }
    }
  }
@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;