.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
        font-weight: 600;
    }
}

.ant-dropdown-menu-item-selected{
    background-color: lighten(@gray, 15%);
}

.ant-divider.ant-divider-horizontal.ant-divider-with-text.ant-divider-with-text-center {
    color: @white;
    border-color: @white;
    border-top: none !important;
}

.ant-menu-title-content {
    font-weight: bold;
}

.ant-input-affix-wrapper {
    padding: 8px 11px !important;
}

.ant-picker-footer {
    display: none;
  }

.ant-picker-input {
    height: 30px;
}


.ant-btn {
    height: 40px;
    font-weight: 600;

    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

a.ant-btn {
    line-height: 37px;
}

.ant-btn-default.default {
    background: #F3F3F7;
    color: #A8A8A8;
    border-color: rgba(#A8A8A8, 0.1);

    &:hover {
        background: darken(#F3F3F7, 3%)
    }
}

.ant-btn-leftIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 8px !important;
            margin-right: 0 !important;
        }
    }
}
.ant-btn-rightIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    
    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 0 !important;
            margin-right: 8px !important;
        }
    }
    >img {
        max-width: 18px;
    }
}
.ant-btn-outlined-secondary {
    color: @secondary-color;
    border-color: @secondary-color;

    &[disabled] {
        border-color: @border;

        &:hover {
            border-color: @border;
        }
    }

    &:hover {
        opacity: .8;
        color: @secondary-color;
        border-color: @secondary-color;
    }
}



//
.ant-select-item {
    color: @primary-color !important;
    background-color: transparent !important;
    
    div.ant-select-item-option-content {
        color: @primary-color;
    }
    
}


.ant-form {

    .form-radio-button-group {
        
        .ant-radio-button-wrapper {
            height: 45px;
            padding-top: 3px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 25px;

            &.calendar-days {
                padding-left: 17px;
                padding-right: 20px;
                width: 45px;
                white-space: nowrap;
                border-radius: 0px;
                height: 45px;
                
            }

            &.selected  {
                background: @primary-color;
                color: @white;
            }

            &.ant-radio-button-wrapper-checked:focus-within{
                box-shadow: none;
                border-color: 1px solid #d9d9d9;
            }

            .ant-radio-button-checked{
                border-color: 1px solid #d9d9d9 !important;
            }

            &.ant-radio-button-wrapper-checked {
                span.ant-radio-button.ant-radio-button-checked{
                    border-color: 1px solid #d9d9d9 !important;
                }

                &:not(.ant-radio-button-wrapper-disabled){
                    border-color: #d9d9d9;
                }
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
                background-color: #d9d9d9;
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                border-color: 1px solid #d9d9d9 !important;
            }
        }
    }

    label {
        font-size: 12px !important;
        color: @secondary-color;
    }
    &-item-explain.ant-form-item-explain-error {
        padding: 10px 8px 20px 0px;
    }

    // Change placeholder color
    .ant-input::placeholder,
    .ant-picker-input > input::placeholder
    {
        color: @dark-gray;
        font-weight: 600;
    }

    .ant-picker-input {

        & > input {
            color: @primary-color;
            font-weight: 600;
        }

        svg {
            fill: @primary-color;
        }
    }

    .ant-input {
        font-weight: 600;
    }

    .ant-select-selection-placeholder {
        color: @dark-gray;
        font-size: 14px;
        font-weight: 600;
    }

    .ant-select-arrow > * {
        color: @dark-gray;
    }

    .ant-select-selection-item {
        font-size: 14px;
        font-weight: 600;
    }

    .ant-input-number {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 272px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 2px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.3s;
    }


    .merchant-image-preview {
        width: 100%;
        height: 272px;
    }

    

    .ant-upload-list-picture-card-container{
        display: inline-block;
        width: 100%;
        height: 272px;
        vertical-align: top;
    }

    .sd-upload-button {
        font-size: 16px;
        font-weight: 900;
        color: @secondary-color;
    }

    .ant-input-affix-wrapper {

        input {
            color: @primary-color;
        }
    }


    &.sd-form {

        .ant-input-prefix {
            margin-right: 0px;
        }
        
    }

}

//Default Ant Button

button.ant-btn.ant-btn-default{
    color: @primary-color;
    font-weight: 600;
}

//Calendar
.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
    display: none;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    font-size: 18px
}

.ant-menu-inline {
    .ant-menu-item::after {
        right: auto;
        left: 0;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: fade(@primary-color,25%);
    color: #ffffff;
}

.anticon svg {
    vertical-align: middle;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 80px;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2);
}

.ant-form-item {
    margin-bottom: 18px !important;
}
.ant-form-item-with-help {
    margin-bottom: 0 !important;
}
.ant-form-item-explain{
    // min-height: 18px !important;
    font-size: 12px !important;
}

a {
    color: @secondary-color;
}

.ant-form-item-control-input-content{
    & > input {
        height: 40px;
        color: @primary-color;
    }

    .ant-select {
        color: @primary-color;
        .ant-select-selector {
            height: 40px !important;
            align-items: center;
        }
    }
}

.ant-table-thead > tr > th {
    background: @secondary-color !important;
    font-weight: 600 !important;
}

.ant-tabs-tab-btn {
    font-weight: 600;
    color: @dark-gray;
}

.ant-layout-sider-children {
    background-color: @white;
}


.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
    span.anticon {
        display: none;
    }
}

@media @max768 {
    .ant-menu-inline-collapsed-tooltip {
        display: none !important;
    }
}


/***** Secret Dates Custom Classes *****/

//Antd Notification

.ant-notification {
    
    .sd-notification {
        background: @white;
        
        .ant-notification-notice-message,
        .ant-notification-notice-description {
            color: @secondary-color !important;
            font-weight: 600;
        }
    }
}

//Badge
.sd-badge {
    .ant-badge-count {
        box-shadow: none;
        background: @primary-color;
        font-weight: 600;
    }
}